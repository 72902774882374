import React from "react";

const MapPin = () => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 22 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 8C9.34315 8 8 9.34315 8 11C8 12.6569 9.34315 14 11 14C12.6569 14 14 12.6569 14 11C14 9.34315 12.6569 8 11 8ZM6 11C6 8.23858 8.23858 6 11 6C13.7614 6 16 8.23858 16 11C16 13.7614 13.7614 16 11 16C8.23858 16 6 13.7614 6 11Z"
        fill="#3E9FF3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 2C8.61305 2 6.32387 2.94821 4.63604 4.63604C2.94821 6.32387 2 8.61305 2 11C2 15.1399 4.31185 18.9096 6.75461 21.7188C7.96377 23.1094 9.1755 24.2305 10.0851 25.0037C10.4449 25.3095 10.7562 25.5599 11 25.75C11.2438 25.5599 11.5551 25.3095 11.9149 25.0037C12.8245 24.2305 14.0362 23.1094 15.2454 21.7188C17.6882 18.9096 20 15.1399 20 11C20 8.61305 19.0518 6.32387 17.364 4.63604C15.6761 2.94821 13.3869 2 11 2ZM11 27C10.4265 27.8192 10.4263 27.819 10.4259 27.8188L10.4251 27.8182L10.4228 27.8166L10.4154 27.8114L10.39 27.7933C10.3683 27.7778 10.3375 27.7557 10.2981 27.7269C10.2192 27.6693 10.1059 27.5853 9.96307 27.4762C9.67747 27.2579 9.27323 26.9384 8.78985 26.5276C7.8245 25.707 6.53623 24.5156 5.24539 23.0312C2.68815 20.0904 0 15.8601 0 11C0 8.08262 1.15893 5.28473 3.22183 3.22183C5.28473 1.15893 8.08262 0 11 0C13.9174 0 16.7153 1.15893 18.7782 3.22183C20.8411 5.28473 22 8.08262 22 11C22 15.8601 19.3118 20.0904 16.7546 23.0312C15.4638 24.5156 14.1755 25.707 13.2101 26.5276C12.7268 26.9384 12.3225 27.2579 12.0369 27.4762C11.8941 27.5853 11.7808 27.6693 11.7019 27.7269C11.6625 27.7557 11.6317 27.7778 11.61 27.7933L11.5846 27.8114L11.5772 27.8166L11.5749 27.8182L11.5741 27.8188C11.5737 27.819 11.5735 27.8192 11 27ZM11 27L11.5735 27.8192C11.2291 28.0603 10.7709 28.0603 10.4265 27.8192L11 27Z"
        fill="#3E9FF3"
      />
    </svg>
  );
};

export default MapPin;
