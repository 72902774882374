import React from "react";

const MailIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66671 6.66667H33.3334C35.1667 6.66667 36.6667 8.16667 36.6667 10V30C36.6667 31.8333 35.1667 33.3333 33.3334 33.3333H6.66671C4.83337 33.3333 3.33337 31.8333 3.33337 30V10C3.33337 8.16667 4.83337 6.66667 6.66671 6.66667Z"
        stroke="#2291F1"
        strokeWidth="2.50"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.6667 10L20 21.6667L3.33337 10"
        stroke="#2291F1"
        strokeWidth="2.50"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MailIcon;
