import React from 'react';

const UserIcon = () => {
    return (
        <svg width="35" height="35" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 31.6667V30C10 26.3182 12.9848 23.3333 16.6667 23.3333H23.3333C27.0152 23.3333 30 26.3182 30 30V31.6667" stroke="#2291F1" strokeWidth="2.58" strokeLinecap="round" />
            <path d="M20 18.3333C17.2385 18.3333 15 16.0948 15 13.3333C15 10.5719 17.2385 8.33334 20 8.33334C22.7615 8.33334 25 10.5719 25 13.3333C25 16.0948 22.7615 18.3333 20 18.3333Z" stroke="#2291F1" strokeWidth="2.58" strokeLinecap="round" />
        </svg>
    );
};

export default UserIcon;