import React from 'react';

const StartIcon = () => {
    return (
        <svg width="40" height="48" viewBox="0 0 40 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 0L25.4341 8.25709L34.1421 7.02944L33.1191 17.4791L40 24L33.1191 30.5209L34.1421 40.9706L25.4341 39.7429L20 48L14.5659 39.7429L5.85786 40.9706L6.88091 30.5209L0 24L6.88091 17.4791L5.85786 7.02944L14.5659 8.25709L20 0Z" fill="#2291F1" />
            <path d="M28 18L17 29L12 24" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    );
};

export default StartIcon;