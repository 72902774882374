import React from "react";

const PaymentBtn = () => {
  return (
    <svg version={1.0} xmlns="http://www.w3.org/2000/svg" width="28px" height="25px" viewBox="0 0 342.000000 259.000000" preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,259.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path d="M1805 2506 c-22 -7 -200 -92 -395 -189 -1289 -635 -1204 -591 -1243
        -639 -20 -25 -44 -64 -52 -88 -19 -53 -19 -145 -1 -196 30 -83 491 -1001 523
        -1041 41 -51 118 -91 193 -98 80 -9 87 0 59 71 l-24 59 -3 560 c-2 394 0 578
        8 620 17 86 54 157 117 226 64 69 118 103 204 130 56 18 100 19 605 19 341 0
        544 4 544 10 0 5 -48 107 -107 227 -116 232 -149 279 -228 314 -58 26 -149 32
        -200 15z" />
            <path d="M1299 1740 c-100 -17 -174 -74 -215 -165 l-24 -52 0 -590 c0 -425 3
        -599 12 -624 19 -56 56 -107 100 -139 86 -62 57 -61 1034 -58 l889 3 51 27
        c59 31 108 84 138 147 21 45 21 57 21 636 l0 590 -34 69 c-24 50 -45 76 -78
        99 -92 65 -34 62 -983 63 -476 1 -886 -2 -911 -6z m1828 -204 c29 -32 34 -43
        31 -79 l-3 -42 -965 0 -965 0 2 43 c1 24 9 55 18 69 36 55 6 53 964 50 l884
        -3 34 -38z m33 -776 c0 -330 -2 -396 -15 -420 -32 -63 12 -60 -952 -60 -677 0
        -884 3 -902 13 -13 6 -34 25 -45 40 -20 28 -21 40 -23 410 -1 210 0 388 2 395
        3 9 206 12 970 12 l965 0 0 -390z" />
            <path d="M1515 775 c-5 -2 -22 -6 -37 -9 -61 -14 -127 -102 -128 -172 0 -46
        44 -125 87 -154 55 -37 143 -36 203 4 28 18 48 25 55 20 51 -38 72 -48 115
        -52 132 -12 233 119 186 243 -47 122 -197 160 -293 72 l-22 -20 -31 26 c-38
        33 -103 53 -135 42z" />
            </g>
        </svg>
  );
};

export default PaymentBtn;
