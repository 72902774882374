import React from "react";

const SchoolIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 12V0M0 38.6667H40M4 38.6667V22.6667H9.33333M36 38.6667V22.6667H30.6667M17.3333 38.6667V30.6667H22.6667V38.6667M20 1.33333H28V6.66667H20M20 12L30.6667 17.3333V38.6667H9.33333V17.3333L20 12ZM20 25.3333C19.2928 25.3333 18.6145 25.0524 18.1144 24.5523C17.6143 24.0522 17.3333 23.3739 17.3333 22.6667C17.3333 21.9594 17.6143 21.2811 18.1144 20.781C18.6145 20.281 19.2928 20 20 20C20.7072 20 21.3855 20.281 21.8856 20.781C22.3857 21.2811 22.6667 21.9594 22.6667 22.6667C22.6667 23.3739 22.3857 24.0522 21.8856 24.5523C21.3855 25.0524 20.7072 25.3333 20 25.3333Z"
        stroke="#2291F1"
        strokeWidth="1.50"
      />
    </svg>
  );
};

export default SchoolIcon;
