import React from 'react';

const BankIcon = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.9998 10.5C16.4971 10.5 16.974 10.3025 17.3256 9.95083C17.6773 9.5992 17.8748 9.12228 17.8748 8.625C17.8748 8.12772 17.6773 7.65081 17.3256 7.29918C16.974 6.94755 16.4971 6.75 15.9998 6.75C15.5025 6.75 15.0256 6.94755 14.674 7.29918C14.3224 7.65081 14.1248 8.12772 14.1248 8.625C14.1248 9.12228 14.3224 9.5992 14.674 9.95083C15.0256 10.3025 15.5025 10.5 15.9998 10.5ZM17.8398 1.6375C17.3197 1.21484 16.67 0.984116 15.9998 0.984116C15.3296 0.984116 14.6799 1.21484 14.1598 1.6375L1.65482 11.8C0.134822 13.04 1.00982 15.5 2.96982 15.5H3.49982V26C2.01482 26.7575 0.999822 28.3 0.999822 30.0825V31.75C0.999822 32.0815 1.13152 32.3995 1.36594 32.6339C1.60036 32.8683 1.9183 33 2.24982 33H29.7498C30.0813 33 30.3993 32.8683 30.6337 32.6339C30.8681 32.3995 30.9998 32.0815 30.9998 31.75V30.0825C30.9998 28.3025 29.9848 26.7575 28.4998 26V15.5H29.0298C30.9898 15.5 31.8648 13.0375 30.3423 11.8L17.8423 1.635L17.8398 1.6375ZM15.7398 3.5775C15.8141 3.51743 15.9068 3.48466 16.0023 3.48466C16.0979 3.48466 16.1905 3.51743 16.2648 3.5775L27.8523 13H4.14482L15.7398 3.575V3.5775ZM25.9998 25.5H23.4998V15.5H25.9998V25.5ZM20.9998 25.5H17.2498V15.5H20.9998V25.5ZM14.7498 25.5H10.9998V15.5H14.7498V25.5ZM26.4173 28C27.5673 28 28.4998 28.9325 28.4998 30.0825V30.5H3.49982V30.0825C3.49982 28.9325 4.43232 28 5.58232 28H26.4173ZM8.49982 25.5H5.99982V15.5H8.49982V25.5Z" fill="#2291F1"/>
</svg>

    );
};

export default BankIcon;